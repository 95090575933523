import React, { useState, useEffect } from "react";
import { Button, Modal, message, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/header/Header";
import "../styles/Cart.css";
import axios from "axios";

import Footer from "../components/Footer/Footer";

const CartPage = () => {
  const { cartItems } = useSelector((state) => state.rootReducer);
  const dispatch = useDispatch();
  const [subTotal, setSubTotal] = useState(0);
  const [billPopup, setBillPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let temp = cartItems.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    setSubTotal(temp);
  }, [cartItems]);

  const handleIncrement = (record) => {
    dispatch({
      type: "UPDATE_CART",
      payload: { ...record, quantity: record.quantity + 1 },
    });
  };

  const handleDecrement = (record) => {
    if (record.quantity !== 1) {
      dispatch({
        type: "UPDATE_CART",
        payload: { ...record, quantity: record.quantity - 1 },
      });
    }
  };

  const handleRemove = (item) => {
    dispatch({ type: "DELETE_FROM_CART", payload: item });
  };
  const handleSubmit = async (value) => {
    // Check if value is defined
    if (!value) {
      message.error("Form data is undefined");
      return;
    }

    // Destructure form values
    const { customerName, customerNumber, paymentMode } = value;

    // Check if customerName is defined and not empty
    if (!customerName || !customerName.trim()) {
      message.error("Please enter customer name");
      return;
    }

    // Check if customerNumber is defined and not empty
    if (!customerNumber || !customerNumber.trim()) {
      message.error("Please enter contact number");
      return;
    }

    // Check if paymentMode is defined
    if (!paymentMode) {
      message.error("Please select payment method");
      return;
    }

    // Proceed with form submission if all fields are valid
    try {
      const newObject = {
        customerName,
        customerNumber,
        paymentMode,
        cartItems,
        subTotal,
        tax: Number(((subTotal / 100) * 10).toFixed(2)),
        totalAmount: Number(
          subTotal + Number(((subTotal / 100) * 10).toFixed(2))
        ),
      };

      await axios.post("http://localhost:8080/api/bills/add-bills", newObject);

      message.success("Order Placed");
      navigate("/orders");
      dispatch({ type: "CLEAR_CART" });
    } catch (error) {
      message.error("Something went wrong");
      console.log(error);
    }
  };

  return (
    <Header>
      <h1 style={{ color: "white", margin: "3rem" }}>Cart</h1>
      <div className="cart">
        <ul className="cart-list">
          {cartItems.map((item) => (
            <li key={item._id}>
              <div>
                <img src={item.img} alt={item.name} />
              </div>
              <div>
                <h3>{item.name}</h3>
                <p>Price: {item.price}</p>
              </div>
              <div>
                <Button
                  icon={<MinusCircleOutlined />}
                  onClick={() => handleDecrement(item)}
                />
                <span>{item.quantity}</span>
                <Button
                  icon={<PlusCircleOutlined />}
                  onClick={() => handleIncrement(item)}
                />
              </div>
              <div className="remove_button">
                <Button type="link" onClick={() => handleRemove(item)}>
                  Remove
                </Button>
              </div>
            </li>
          ))}
        </ul>
        <div className="checkout">
          <h2 style={{ color: "white" }}>Summary </h2>
          <p className="subtotal" style={{ color: "white" }}>
            Rs. {subTotal}
          </p>
          <p className="total_price" style={{ color: "white" }}>
            Rs. {((subTotal / 100) * 10).toFixed(2)}
          </p>
          <p className="foods_count" style={{ color: "white" }}>
            Rs. {subTotal + Number(((subTotal / 100) * 10).toFixed(2))}
          </p>
          <Link onClick={() => setBillPopup(true)}>Proceed to Checkout</Link>
        </div>
      </div>
      <Modal
        title="Invoice"
        visible={billPopup}
        onCancel={() => setBillPopup(false)}
        footer={null}
        style={{ backgroundColor: "#f0f0f0" }} // Set background color of modal
      >
        <div className="invoice-form">
          <Form layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              label="Name"
              name="customerName"
              rules={[
                { required: true, message: "Please enter customer name" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Contact Number"
              name="customerNumber"
              rules={[
                { required: true, message: "Please enter contact number" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Payment Method"
              name="paymentMode"
              rules={[
                { required: true, message: "Please select payment method" },
              ]}
            >
              <Select style={{ width: "100%" }}>
                <Select.Option value="cash">Cash</Select.Option>
                <Select.Option value="card">
                  Card(not working yet)
                </Select.Option>
              </Select>
            </Form.Item>

            <div className="checkout-button">
              <Button
                type="primary"
                htmlType="submit"
                style={{ backgroundColor: "gold", borderColor: "#443300" }}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
      <Footer />
    </Header>
  );
};

export default CartPage;
