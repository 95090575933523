import img1 from "../assets/lock1.png";
import img2 from "../assets/lock-2.png";
import img3 from "../assets/lock-3.png";
import img4 from "../assets/lock-8.png";
import img5 from "../assets/lock-5.png";
import img6 from "../assets/lock-6.png";
import img7 from "../assets/lock-7.png";
import img8 from "../assets/lock-8.png";
import img9 from "../assets/lock1.png";
import img10 from "../assets/lock1.png";
import img11 from "../assets/lock-2.png";
import img12 from "../assets/lock-6.png";
import img13 from "../assets/lock-5.png";
import img14 from "../assets/lock-2.png";

export const SliderProducts = [
  {
    name: "STRONG LOCK",
    detail: "Super Lock",
    price: "$250",
    img: img1,
  },
  {
    name: "KEYLESS LOCK",
    detail: "Safety Lock",
    price: "$210",
    img: img2,
  },
  {
    name: "BIOMETRIC LOCK",
    detail: "Quality Lock",
    price: "$200",
    img: img3,
  },
];
export const ProductsData = [
  {
    name: "SMART DOOR LOCK",
    detail: "Multiple ways to unlock",
    price: "$150",
    img: img1,
    type: "smart door lock",
  },
  {
    name: "FACE DOOR LOCK",
    detail: "3-in-1 Camera + Doorbell + Fingerprint",
    price: "$250",
    img: img2,
    type: "face door lock",
  },
  {
    name: "THUMBPRINT DOOR LOCK",
    detail: "Dead Bold Lock Body ",
    price: "$399",
    img: img3,
    type: "thumbprint door lock",
  },
  {
    name: "SMART DOOR LOCK",
    detail: "Super Quality Lock",
    price: "$320",
    img: img4,
    type: "smart door lock",
  },
  {
    name: "SMART DOOR LOCK",
    detail: "Perfect Solution",
    price: "$250",
    img: img5,
    type: "thumbprint door lock",
  },
  {
    name: "FACE DOOR LOCK",
    detail: "Smart Wifi Door Lock",
    price: "$250",
    img: img6,
    type: "face door lock",
  },
  {
    name: "FACE DOOR LOCK",
    details: "Best Door Lock",
    price: "$300",
    img: img8,
    type: "conditioner",
  },
  {
    name: "SMART DOOR LOCK",
    details: "Great Lock",
    price: "$230",
    img: img9,
    type: "smart door lock",
  },
  {
    name: "Face DOOR LOCK",
    detail: "Metallic Body ",
    price: "$599",
    img: img7,
    type: "face door lock",
  },
  {
    name: "THUMBPRINT DOOR LOCK",
    detail: "Hard Body ",
    price: "$299",
    img: img6,
    type: "thumbprint door lock",
  },
  {
    name: "SMART DOOR LOCK",
    detail: "Dead Bold Lock Body ",
    price: "$399",
    img: img4,
    type: "smart door lock",
  },
  {
    name: "THUMBPRINT DOOR LOCK",
    detail: "Dead Bold Lock Body ",
    price: "$199",
    img: img2,
    type: "thumbprint door lock",
  },
  {
    name: "Face DOOR LOCK",
    detail: "Smart Face Lock ",
    price: "$399",
    img: img6,
    type: "face door lock",
  },
  {
    name: "SMART DOOR LOCK",
    details: "Great Lock",
    price: "$230",
    img: img9,
    type: "smart door lock",
  },
  {
    name: "Face DOOR LOCK",
    detail: "Metallic Body ",
    price: "$599",
    img: img7,
    type: "face door lock",
  },
  {
    name: "THUMBPRINT DOOR LOCK",
    detail: "Hard Body ",
    price: "$299",
    img: img6,
    type: "thumbprint door lock",
  },
  {
    name: "SMART DOOR LOCK",
    detail: "Dead Bold Lock Body ",
    price: "$399",
    img: img4,
    type: "smart door lock",
  },
  {
    name: "THUMBPRINT DOOR LOCK",
    detail: "Dead Bold Lock Body ",
    price: "$199",
    img: img2,
    type: "thumbprint door lock",
  },
  {
    name: "Face DOOR LOCK",
    detail: "Smart Face Lock ",
    price: "$399",
    img: img6,
    type: "face door lock",
  },
];
