import "./App.css";
import Homepage from "../src/pages/Homepage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Categories from "../src/pages/Categories";
import Cart from "./pages/Cart";
import SuccessPage from "./pages/SuccessPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/orders" element={<SuccessPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
