import React from "react";
import css from "./Header.module.css";
import Logo from "../../assets/logo.png";
import { CgShoppingBag } from "react-icons/cg";
import { Layout, Menu, Button } from "antd";
import { GoThreeBars } from "react-icons/go";
import { useState } from "react";
import { Link } from "react-router-dom"; // Change from 'useNavigate' and 'useParams' to 'Link'
import { useSelector } from "react-redux";

const { Content } = Layout;

const Header = ({ children }) => {
  const [ShowMenu, setShowMenu] = useState(true);
  const { cartItems } = useSelector((state) => state.rootReducer);

  const toggleMenu = () => {
    setShowMenu((ShowMenu) => !ShowMenu);
  };
  const totalItems = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  return (
    <Layout>
      <div className={css.container}>
        <div className={css.logo}>
          <img src={Logo} alt="" />
          <span>AUSAJA GENERAL TRADING FZ-LLC</span>
        </div>
        <div className={css.right}>
          <div className={css.bars} onClick={toggleMenu}>
            <GoThreeBars />
          </div>
          <ul
            className={css.menu}
            style={{
              display: ShowMenu ? "inherit" : "none",
            }}
          >
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/categories">Categories</Link>
            </li>
          </ul>

          <input type="text" className={css.search} placeholder="Search" />
          <Link to="/cart">
            <div className={css.cartContainer}>
              <CgShoppingBag className={css.cart} />
              {totalItems > 0 && (
                <span className={css.cartBadge}>{totalItems}</span>
              )}
            </div>
          </Link>
        </div>
      </div>{" "}
      <Content style={{ background: "#000" }}>{children}</Content>
    </Layout>
  );
};

export default Header;
