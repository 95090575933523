// SuccessPage.js

import React from "react";
import "../styles/SuccessPage.css";
import Header from "../components/header/Header";

const SuccessPage = () => {
  return (
    <div className="success-page">
      <h1>Your Order Was Successfully Placed!</h1>
      <p>
        Thank you for your purchase. You will receive a confirmation email
        shortly.
      </p>
      {/* You can display order details here */}
    </div>
  );
};

export default SuccessPage;
