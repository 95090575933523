import React from "react";
import Header from "../components/header/Header";
import "../styles/Homepage.css";
import HeroImg from "../../src/assets/banner.png";
import { RiShoppingBagFill } from "react-icons/ri";
import { BsArrowRight } from "react-icons/bs";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { SliderProducts } from "../data/products";
import Slider from "../components/Slider/Slider";
import Products from "../components/Products/Products";
import Testimonials from "../components/Testimonials/Testimonials";
import Footer from "../components/Footer/Footer";
import { useDispatch } from "react-redux";

const Homepage = () => {
  const dispatch = useDispatch();

  // Update cart handler
  const handleAddToCart = (selectedItem) => {
    console.log("SelectedItem to be added to cart:", selectedItem);
    dispatch({
      type: "ADD_TO_CART",
      payload: { ...selectedItem, quantity: 1 },
    });
  };

  const transition = { duraion: 3, type: "spring" };
  return (
    <Header>
      <div className="body">
        <div className="container">
          {/* left side */}
          <div className="h_sides">
            <span className="text1">Smart Door Locks</span>
            <div className="text2">
              <span>Meet The Elegence</span>
              <span>
                {""}
                We Have All Smart Locks
              </span>
            </div>
          </div>
          {/*Middle side hero image */}
          <div className="wrapper">
            {/*Blue Circle */}

            <motion.div
              initial={{ bottom: "-2rem" }}
              whileInView={{ bottom: "0rem" }}
              transition={transition}
              className="blueCircle"
            ></motion.div>
            <motion.img
              initial={{ bottom: "2rem" }}
              whileInView={{ bottom: "0rem" }}
              transition={transition}
              src={HeroImg}
              alt=""
              width={600}
            />
            <motion.div
              transition={transition}
              initial={{ right: "4%" }}
              whileInView={{ right: "2%" }}
              className="cart2"
            >
              {/* <RiShoppingBagFill /> */}
              <div className="signup">
                <span>Best Signup Offers</span>
                <div>
                  <BsArrowRight />
                </div>
              </div>
            </motion.div>
          </div>
          {/*Right Sides */}
          <div className="h_sides">
            <div className="traffic">
              <span>1.5k</span>
              <span>Monthly Traffic</span>
            </div>
            <div className="customers">
              <span>100k</span>
              <span>Happy Customers</span>
            </div>
          </div>
        </div>
        <Slider />
        <Products />
        <Testimonials />
        <Footer />
      </div>
    </Header>
  );
};

export default Homepage;
