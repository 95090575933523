import React, { useState, useEffect } from "react";
import Header from "../components/header/Header";
import "../styles/Categories.css";
import Plane from "../assets/plane.png";
import { ProductsData } from "../data/products"; // Import ProductsData array
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useDispatch } from "react-redux";

const Categories = () => {
  const [selectedCategory, setSelectedCategory] = useState("All"); // Default category
  const [parent] = useAutoAnimate();
  const [products, setProducts] = useState([]); // State to store fetched products
  const [filteredProducts, setFilteredProducts] = useState([]); // State to store filtered products
  const dispatch = useDispatch();
  // Update cart handler
  const handleAddToCart = (selectedItem) => {
    console.log("SelectedItem to be added to cart:", selectedItem);
    dispatch({
      type: "ADD_TO_CART",
      payload: { ...selectedItem, quantity: 1 },
    });
  };

  useEffect(() => {
    // You can remove the fetch call as you're using static data now
    console.log("Fetched products:", ProductsData);
    setProducts(ProductsData);
    setFilteredProducts(ProductsData); // Initialize filteredProducts with fetched data
  }, []);

  const filterProducts = (category) => {
    console.log("Filtering by category:", category);
    setSelectedCategory(category);
    if (category === "All") {
      console.log("Showing all products");
      setFilteredProducts(products); // If "All" selected, show all products
    } else {
      console.log("Filtering products by category:", category);
      const filtered = products.filter(
        (product) => product.type.toLowerCase() === category.toLowerCase()
      );
      console.log("Filtered products:", filtered);
      setFilteredProducts(filtered);
    }
  };

  return (
    <Header>
      <div className="body">
        <div className="box">
          <img src={Plane} alt="" />
          <h1>Our Featured Products</h1>
          <div className="products">
            <ul className="menu">
              <li onClick={() => filterProducts("All")}>All</li>
              <li onClick={() => filterProducts("smart door lock")}>
                Smart Door Locks
              </li>
              <li onClick={() => filterProducts("face door lock")}>
                FACE DOOR LOCK
              </li>
              <li onClick={() => filterProducts("thumbprint door lock")}>
                THUMBPRINT DOOR LOCK
              </li>
            </ul>
            <div className="list" ref={parent}>
              {filteredProducts.map((product, i) => (
                <div className="product" key={i}>
                  <div className="product-design">
                    <div className="product-name">
                      <span style={{ color: "black", fontWeight: "500" }}>
                        {product.name}
                      </span>
                      <span style={{ color: "black", fontSize: "0.8rem" }}>
                        <br />
                        {product.detail}
                      </span>
                    </div>
                    <span
                      className="productPrice"
                      style={{
                        color: "black",
                        fontSize: "1rem",
                        fontWeight: "500",
                      }}
                    >
                      ${product.price}
                    </span>
                    {/* "Shop Now" button with onClick event to add product to cart */}
                    <div
                      style={{
                        color: "black",
                        borderRadius: "30px",
                        border: "1px solid black",
                        padding: "5px 10px",
                        width: "max-content",
                        marginTop: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleAddToCart(product)}
                    >
                      Shop Now
                    </div>
                  </div>
                  <img src={product.img} alt="" className="img-p" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Header>
  );
};

export default Categories;
