import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Slider.css";
import { ProductsData } from "../../data/products"; // Importing ProductsData array
import { useDispatch } from "react-redux";

const Slider = () => {
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();

  // Update cart handler
  const handleAddToCart = (selectedItem) => {
    console.log("SelectedItem to be added to cart:", selectedItem);
    dispatch({
      type: "ADD_TO_CART",
      payload: { ...selectedItem, quantity: 1 },
    });
  };

  useEffect(() => {
    // Fetching data
    // fetch("http://localhost:8080/api/items/get-item")
    //   .then((res) => res.json())
    //   .then((data) => setProducts(data))
    //   .catch((err) => console.error(err));

    // Setting products data
    setProducts(ProductsData);
  }, []);

  return (
    <div className="s-container">
      <Swiper
        breakpoints={{
          640: {
            slidesPerView: 3,
          },
          0: {
            slidesPerView: 1,
          },
        }}
        modules={[Pagination, Navigation]}
        className="mySwiper"
        navigation={true}
        loopFillGroupWithBlank={true}
        slidesPerView={3}
        spaceBetween={40}
        slidesPerGroup={1}
        loop={true}
      >
        {products.map((product, i) => (
          <SwiperSlide key={i}>
            <div className="left-s">
              <div className="name">
                <span>{product.name}</span>
                <span>{product.detail}</span>
              </div>
              <span>${product.price}</span>
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={() => handleAddToCart(product)}
              >
                Shop Now
              </div>
            </div>
            <img src={product.img} alt="product" className="img-p" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
