import profile1 from "../assets/profile1.png";
import profile2 from "../assets/profile2.png";

export const TestimonialsData = [
  {
    image: profile1,
    comment:
      "Fantastic service from Ausaja General Trading! Their range of locks is impressive, and the staff are knowledgeable and helpful. I felt confident in my purchase, and the lock I received was exactly as described. Highly recommend checking them out!",
    name: "John Alendon",
  },
  {
    image: profile2,
    comment:
      "I've been a customer of Ausaja General Trading for years, and they never disappoint. Their selection of locks is extensive, catering to all needs. Whether it's for home or business, you'll find what you're looking for here!",
    name: "Natasha",
  },
  {
    image: profile1,
    comment:
      "Ausaja General Trading provided top-notch service! The locks I purchased were high quality and exactly what I needed. Fast shipping and excellent customer support. Highly recommended",
    name: "Alex Hormonzi",
  },
  {
    comment:
      "The website was easy to navigate, and I found the perfect lock for my needs. Ordering was a breeze, and the lock arrived promptly. A trustworthy company with great products!",
    name: "Ibrahim",
  },
  {
    comment:
      "Ausaja General Trading exceeded my expectations. The lock I ordered was competitively priced and of excellent quality. The transaction was smooth, and delivery was quick. I'll definitely be a returning customer!",
    name: "Fanooza",
  },
];
