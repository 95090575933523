import React, { useState, useEffect } from "react";
import css from "./Products.module.css";
import Plane from "../../assets/plane.png";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { ProductsData } from "../../data/products"; // Importing ProductsData array

const Products = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [parent] = useAutoAnimate();
  const [selectedCategory, setSelectedCategory] = useState("All"); // Default category
  const dispatch = useDispatch();

  // Update cart handler
  const handleAddToCart = (selectedItem) => {
    console.log("SelectedItem to be added to cart:", selectedItem);
    dispatch({
      type: "ADD_TO_CART",
      payload: { ...selectedItem, quantity: 1 },
    });
  };

  useEffect(() => {
    // You can remove the fetch call as you're using static data now
    console.log("Fetched products:", ProductsData);
    setProducts(ProductsData);
    setFilteredProducts(ProductsData); // Initialize filteredProducts with fetched data
  }, []);

  const filterProducts = (category) => {
    console.log("Filtering by category:", category);
    setSelectedCategory(category);
    if (category === "All") {
      console.log("Showing all products");
      setFilteredProducts(products); // If "All" selected, show all products
    } else {
      console.log("Filtering products by category:", category);
      const filtered = products.filter(
        (product) => product.type.toLowerCase() === category.toLowerCase()
      );
      console.log("Filtered products:", filtered);
      setFilteredProducts(filtered);
    }
  };

  return (
    <div className={css.container}>
      <img src={Plane} alt="" />
      <h1>Our Featured Products</h1>
      <div className={css.products}>
        <ul className={css.menu}>
          <li onClick={() => filterProducts("All")}>All</li>
          <li onClick={() => filterProducts("smart door lock")}>
            Smart Door Locks
          </li>
          <li onClick={() => filterProducts("face door lock")}>
            FACE DOOR LOCK
          </li>
          <li onClick={() => filterProducts("thumbprint door lock")}>
            THUMBPRINT DOOR LOCK
          </li>
        </ul>
        <div className={css.list} ref={parent}>
          {filteredProducts.map((product, i) => (
            <div className={css.product} key={i}>
              <div className="product-design">
                <div className="product-name">
                  <span style={{ color: "black", fontWeight: "500" }}>
                    {product.name}
                  </span>
                  <span style={{ color: "black", fontSize: "0.8rem" }}>
                    <br />
                    {product.detail}
                  </span>
                </div>
                <span
                  className={css.productPrice}
                  style={{
                    color: "black",
                    fontSize: "1rem",
                    fontWeight: "500",
                  }}
                >
                  ${product.price}
                </span>
                <div
                  style={{
                    color: "black",
                    borderRadius: "30px",
                    border: "1px solid black",
                    padding: "5px 10px",
                    width: "max-content",
                    marginTop: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleAddToCart(product)}
                >
                  {" "}
                  Shop Now{" "}
                </div>
              </div>
              <img src={product.img} alt="" className="img-p" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Products;
